import React from "react"
import { navigate } from "gatsby"

// import Layout from "../components/layout"
import SEO from "../components/seo"

const NotFoundPage = () => (
  // <Layout>
  //   <SEO title="404: Not found" />
  //   <h1>NOT FOUND</h1>
  //   <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
  //   {navigate('/')}
  // </Layout>
  <React.Fragment>
    <SEO title="404: Not found" />
    {typeof window !== "undefined" ? navigate("/") : null}
  </React.Fragment>
)

export default NotFoundPage
